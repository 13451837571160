import React from 'react';
import './main.css';
import { Navbar } from './Components/Navigationbar/navbar';
import { Footer } from './Components/Footer/footer';
import { ContactUsButton } from './Components/Contactusbutton/contactusbutton';
import { NavLink } from 'react-router-dom';
import { BurgerMenu } from './Components/Burger Menu/burgermenu';

export function AboutApp() {

  return (
<div className="AboutApp">
    <body>
    < Navbar />
    < BurgerMenu />
    <main>
<section>
<div className="container">
  <div className="deck">
    <div className="card hovercard">
      <div data-aos="slide-up" data-aos-duration="1000" className="front face">
      </div>
      <div className="back face">
        <br></br>
        <h3><strong>Charlie Collins</strong>
        <br></br>
        <em>Founder & Developer</em>
        </h3>
        <p className="para4"><strong>Charlie started "Made You Click" in 2023 after training as a Front End Developer. His knowledge and skills include: <strong>HTML, CSS, JS, JSX, REACT, PHP, GIT, GITBASH, GITHUB, NODE & VSCODE. </strong>
        All of the projects on our portfolio page and this website were designed, written, developed, tested and launched by him.</strong></p>
      </div>
    </div>
    <div className="card hovercard">
      <div data-aos="slide-up" data-aos-duration="2000" className="front2 face">
      </div>
      <div className="back face">
        <br></br>
        <h3><strong>Jamie Pryor</strong>
        <br></br>
        <em>Graphic Designer</em>
        </h3>
        <p className="para4"><strong>Jamie joined "Made You Click" in January 2024 as our Graphic and Multimedia Designer to help create unique and on brand logos and designs for our clients.
              Jamie is a very talented digital artist who has spent years producing artwork and designs that have been used online or within publications across the South East.</strong></p>
      </div>
    </div>
  </div>
</div>
</section>
<section>
<h3>Our Journey</h3>
<br></br>
<br></br>
<br></br>
  <div className="timeline">
    <div className="timecontainer left">
      <div data-aos="slide-up" data-aos-duration="2000" className="timecontent">
      <h3><em>October 2023</em></h3>
      <p className="para10">
      <center><strong>"Made You Click"</strong> was created in October 2023.</center>
      <br></br>
        "I have always wanted to start my own business in a sector that not only suited my skill set but one where I could be truly proud of the
        work I am producing. Having worked in multiple industries over the last 12 years, I can utilise all of my prior experience into a passion 
        that combines my creativity, work ethic and high quality standards to bring other people's passions to life through their websites." - Charlie
      </p>
      </div>
    </div>
    <div className="timecontainer right">
      <div data-aos="slide-up" data-aos-duration="2000" className="timecontent">
        <h3><em>November 2023</em></h3>
        <p className="para10">
        <center><strong>New Team Member!</strong></center>
        <br></br>
        We are very happy to announce that Jamie Pryor has joined <strong>"Made You Click"</strong> as our freelance Graphic Designer.
        Jamie has worked as a Graphic Designer for distributed publications across Kent and Sussex as well as undertaking a huge amount
        of freelance projects over the last eight years for multiple clients. He will be designing any unique logos, images and branding 
        that our clients need as part of our web development packages.
        </p>
      </div>
    </div>
    <div className="timecontainer left">
      <div data-aos="slide-up" data-aos-duration="2000" className="timecontent">
        <h3><em>November 2023</em></h3>
        <p className="para10">
        <center><strong>Project completed!</strong></center>
        <br></br>
        This month we have completed work on the <strong>"Queens Estates"</strong> website. They are an online Estate Agency based in
        Kent in the South East of England and came to us to develop a simple and clear website to help get their new business off the ground.
        We worked closely with them to create a friendly and easy to use website where perspective buyers can find their new home and sellers can
        get their property valued in preperation to come to market. 
        <br></br>
        <br></br>
        Click <NavLink className="" to="/queensestates" activeClassName="active"  onClick={() => {window.scroll(0, 0);}}>here</NavLink> to view their website.
        </p>
      </div>
      </div>
      <div className="timecontainer right">
      <div data-aos="slide-up" data-aos-duration="2000" className="timecontent">
        <h3><em>January 2024</em></h3>
        <p className="para10">
        <center><strong>Website Rebranded!</strong></center>
        <br></br>
        <strong>"Made You Click"</strong> has undergone a makeover! We have rebuilt the whole website using React and JSX. 
        It was also a good opportunity to rebrand and redesign pretty much everything which has created a completely different feel to the site. 
        Our goal was to create a clear and attractive website that covers all of our business information. Using React allowed
        us to experiment alot more with animations and layouts than we could previously and we are delighted with the result!
        </p>
      </div>
    </div>
    <div className="timecontainer left">
      <div data-aos="slide-up" data-aos-duration="2000" className="timecontent">
        <h3><em>January 2024</em></h3>
        <p className="para10">
        <center><strong>Project completed!</strong></center>
        <br></br>
        This month we have completed work on the <strong>"JPT Body Transformation Coach"</strong> website. Jason is an online and in-person 
        Personal Trainer based in Tunbridge Wells in Kent and he wanted us to create a modern, clear, bold and impactful website that his clients can
        use to keep up to date with him and his sessions, as well as informing new clients what he is all about and where to find him.
        Clients can get in touch with him through the website and we will look to incorporate his mobile app and booking system into the site in the future.
        <br></br>
        <br></br>
        Click <NavLink className="" to="/jpt" activeClassName="active"  onClick={() => {window.scroll(0, 0);}}>here</NavLink> to view his website.
        </p>
      </div>
      </div>
      <div className="timecontainer right">
      <div data-aos="slide-up" data-aos-duration="2000" className="timecontent">
        <h3><em>February 2024</em></h3>
        <p className="para10">
        <center><strong>We are on Fiverr!</strong></center>
        <br></br>
        We are now on Fiverr! Fiverr is a multi-national online marketplace for freelance services. Fiverr's platform connects freelancers like myself
        to people or businesses looking to hire professionals in their industry, which encourages a wide range of services in a free market.
        Fiverr is regulated and has a secure upfront method of payment to put your mind even more at ease when purchasing our services.
        <br></br>
        <br></br>
        You can find the link to our Fiverr profile <a className='fiverrlink' href="https://www.fiverr.com/madeyouclick/create-a-clear-bold-and-stylish-website-for-your-business" target="_blank" title="Open Fiverr">here</a>!
        </p>
      </div>
    </div>
  </div>
</section>
< ContactUsButton />

    </main>
    < Footer />
    </body>

</div>

)};

