import React from 'react';
import './main.css';
import { Navbar } from './Components/Navigationbar/navbar';
import { ContactUsButton } from './Components/Contactusbutton/contactusbutton';
import { Footer } from './Components/Footer/footer';
import pricesimage from '../src/Images/pricesimage.jpg';
import { BurgerMenu } from './Components/Burger Menu/burgermenu';

export function PricesApp() {
  return (
<div className="PricesApp">
    <body>
    < Navbar />
    < BurgerMenu />
    <main>
      <div className="pricescontainer">
        <div className="pricesbox">
      <p data-aos="zoom-in" className="pricestop para7">
        <strong>Prices</strong>
        <br></br>
        <br></br>
        <em>We have a three-stage payment structure for our projects as follows:</em>
        <br></br>
        <br></br><strong>Payment 1</strong> - We require an upfront payment of 50% to begin to working on your project.
        <br></br><strong>Payment 2</strong> - When the website goes live online and it works correctly, we will ask for a second payment of 25%.
        <br></br><strong>Payment 3</strong> - We maintain the website for a month for free, to check everything is okay, then we will ask for a final payment of 25%.
        <br></br>
        <br></br>During the time between first and last payment we will make revisions of your project outlined below until you are completely happy.
        <br></br>
        <br></br>
        After the project is completed we can maintain the website for you on a rolling monthly payment as stated below.
        <br></br>
        <br></br>
        Alternatively you can purchase our website packages <a className='fiverrlink' href="https://www.fiverr.com/madeyouclick/create-a-clear-bold-and-stylish-website-for-your-business" target="_blank" title="Open Fiverr">here</a> through Fiverr.
        <br></br>
        <br></br>
      </p>
        </div>
        <div className="pricesbox">
        <img data-aos="fade-right" className="pricesimg" title="Prices" src={pricesimage} alt=""/>
        </div>
      </div>
      <div data-aos="fade-up" className="prices">
      <em>
      <h3>Please Note</h3>
      <p className="para2">
        All of our websites are built to be responsive, which means the layout will change depending on which screen size it is being viewed on - whether its PC, tablet or mobile.
        Every website will include links to social media, quick loading speeds, a security certificate to protect you and your users and search engine optimization (SEO). We host all
        of our sites using IONOS.
      </p>
      </em>
      <h3>Full E-commerce Store</h3><p className="para2">Everything you need to make a professional, fully functional, e-commerce store for your online business that includes everything the professional site package does, 
      plus the ability for people to browse and buy your products online through the site with payment processing. 
      <br></br>
      <br></br>
      <center><strong> Not currently available</strong></center></p>
      <br></br>
      <h3>Professional Website</h3><p className="para2">A large and powerful website with everything you could possibly need for your online business. 7 Revisions.
      <br></br>
      <br></br>
      <center><strong> £1000</strong></center></p>
      <br></br>
      <h3>Standard Website</h3><p className="para2">A website with multiple pages, much more content, animations, further security and SEO optimization. 5 Revisions.
      <br></br>
      <br></br>
      <center><strong> £600</strong></center></p>
      <br></br>
      <h3>Basic Website</h3><p className="para2">A single landing page ideal for small projects like a personal blog, photographer or small business. 3 Revisions.
      <br></br>
      <br></br>
      <center><strong> £350</strong></center></p>
      <br></br>
      <h3>Website Maintenance</h3><p className="para2">We can keep updating, maintaining and hosting your new website once it's live online.
      <br></br>
      <br></br>
      <center><strong> £30/month</strong></center></p>
      </div>

    < ContactUsButton />
    </main>
    < Footer />
    </body>

</div>

)};

