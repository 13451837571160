import React from 'react';
import './footer.css';
import fiverrimg from '../../Images/fiverr.png';
import instaimg from '../../Images/insta.png';
import tiktokimg from '../../Images/tiktok.png';
import { NavLink } from 'react-router-dom';


export function Footer() {
return (
<html>
<footer>
	<div className="footer">
        <div className="socials-wrapper">
		    <div className="newsletter">
                <form name="contact_form" action="../newsletter-form-handler.php" method="POST">
                    <h4>Sign up to our Newsletter</h4>
                        <nobr></nobr><input type="text" placeholder="Enter your email" name="email" required minlength="6" maxlength="100"/>
                        <button className="buttonnews">Send</button>
                </form>
		    </div>
            <div className="links">
        <p>
             &copy; Made You Click LTD 2024 - All rights reserved. 
	        <br></br>Registered in England & Wales 14705887.
	        <br></br><NavLink className="" to="/privacypolicy" activeClassName="active"  onClick={() => {window.scroll(0, 0);}}>Privacy Policy</NavLink> | <NavLink className="" to="/termsandconds" activeClassName="active"  onClick={() => {window.scroll(0, 0);}}>Terms & Conditions</NavLink>
        </p>
        </div>
		    <div className="stayconnected">
				<h4>Join Us on:</h4>
                <a href="https://www.instagram.com/made.you.click/" target="_blank" title="Instagram"><img title="" src={instaimg} alt="Instagram"/></a>
				<a href="https://www.facebook.com/madeyouclick.co.uk" target="_blank" title="Facebook"><img title="" src={tiktokimg} alt="Facebook"/></a>
				<a href="https://www.fiverr.com/madeyouclick/create-a-clear-bold-and-stylish-website-for-your-business" target="_blank" title="Fiverr"><img title="" src={fiverrimg} alt="Fiverr"/></a>
		    </div>
        </div>
    </div>
</footer>
</html>

)};