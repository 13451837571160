import React, { useEffect } from 'react';
import './stepsandtech.css';
import htmlimage from '../../Images/html.png';
import cssimage from '../../Images/css.png';
import jsimage from '../../Images/js.png';
import reactimage from '../../Images/react.png';
import vsimage from '../../Images/vs.png';
import githubimage from '../../Images/github.png';
import gitimage from '../../Images/git.png';
import phpimage from '../../Images/php.png';
import discordimage from '../../Images/discord.png';
import Aos from "aos";
import "aos/dist/aos.css"

export function StepsAndTech() {
    useEffect(() => {
        Aos.init({duration: 2000});
    },[])
return (
<html>
<div data-aos="fade-up" className="section-wrapper">
    <div className="section-1">
    <section>
	    <div id="steps">
        <br></br>
        <br></br>
        <br></br>
            <h3>Development Process</h3>
                <ul class="stepsboxes">
                    <div class="stepsbox1">
                        <li>Concept & Planning</li>
                        <li>Research & Exploration</li>
                        <li>Design & Branding</li>
                    </div>
                    <div class="stepsbox2">
                        <li>Content & Media Creation</li>
                        <li>Development & Coding</li>
                        <li>Testing & Deployment</li>
                    </div>
                </ul>
        </div>
    </section>
    </div>
    <div className="section-2">
    <section>
	<div id="tech-container">
        <br></br>
        <br></br>
        <br></br>
			<h3>Technologies</h3>
		<div className="tech-boxes">
                <div className="tech-box">
                    <a href="https://www.google.com/search?q=html&rlz=1C1CHBF_en-GBGB978GB978&oq=html&gs_lcrp=EgZjaHJvbWUyDwgAEEUYORiDARixAxiABDIGCAEQRRg7MgYIAhBFGDwyBggDEEUYPDIGCAQQRRg8MgYIBRBFGEEyBggGEEUYQTIGCAcQRRhB0gEHNTI2ajBqN6gCALACAA&sourceid=chrome&ie=UTF-8" target="_blank" title="HTML"><img title="" src={htmlimage} alt="htmlimage"/></a>
                </div>
                <div className="tech-box">
                    <a href="https://www.google.com/search?q=css&sca_esv=572573644&rlz=1C1CHBF_en-GBGB978GB978&ei=4SQnZdOEJ42qhbIP7Mmw0AM&ved=0ahUKEwiT-Y_UiO-BAxUNVUEAHewkDDoQ4dUDCBA&uact=5&oq=css&gs_lp=Egxnd3Mtd2l6LXNlcnAiA2NzczIQEAAYigUYsQMYgwEYyQMYQzIHEAAYigUYQzIHEAAYigUYQzIHEAAYigUYQzIHEAAYigUYQzIIEAAYigUYkgMyBxAAGIoFGEMyBxAAGIoFGEMyChAAGIoFGLEDGEMyBxAAGIoFGENIkwdQAFjEAnAAeAGQAQCYAUCgAaUBqgEBM7gBA8gBAPgBAcICDRAuGIoFGMcBGNEDGEPCAhMQLhiKBRixAxiDARjHARjRAxhDwgINEAAYigUYsQMYgwEYQ8ICCxAAGIAEGLEDGIMBwgIREC4YgAQYsQMYgwEYxwEY0QPiAwQYACBBiAYB&sclient=gws-wiz-serp" target="_blank" title="CSS"><img title="" src={cssimage} alt="cssimage"/></a>
                </div>
                <div className="tech-box1">
                    <a href="https://www.google.com/search?q=js&sca_esv=572573644&rlz=1C1CHBF_en-GBGB978GB978&ei=wSUnZdfCLKWuhbIPh6GdiAQ&ved=0ahUKEwiXp_2-ie-BAxUlV0EAHYdQB0EQ4dUDCBA&uact=5&oq=js&gs_lp=Egxnd3Mtd2l6LXNlcnAiAmpzMg0QABiKBRixAxiDARhDMg0QABiKBRixAxiDARhDMgcQABiKBRhDMg0QABiKBRixAxiDARhDMgcQABiKBRhDMgcQABiKBRhDMgcQABiKBRhDMgcQABiKBRhDMgcQABiKBRhDMgcQABiKBRhDSLsEUABYxQFwAHgBkAEAmAFPoAGBAaoBATK4AQPIAQD4AQHCAhEQLhiABBixAxiDARjHARjRA8ICDhAuGMcBGLEDGNEDGIAEwgILEC4YigUYsQMYgwHCAgsQABiKBRixAxiDAcICDhAuGIAEGLEDGMcBGNED4gMEGAAgQYgGAQ&sclient=gws-wiz-serp" target="_blank" title="JS"><img title="" src={jsimage} alt="jsimage"/></a>
                </div>
                <div className="tech-box">
                    <a href="https://www.google.com/search?q=react&sca_esv=572573644&rlz=1C1CHBF_en-GBGB978GB978&ei=0SUnZcDeJ4WohbIP-fyn2AU&oq=react&gs_lp=Egxnd3Mtd2l6LXNlcnAiBXJlYWN0KgIIADINEAAYigUYsQMYgwEYQzINEAAYigUYsQMYgwEYQzINEAAYigUYsQMYgwEYQzINEAAYigUYsQMYgwEYQzIHEAAYigUYQzINEAAYigUYsQMYgwEYQzIHEAAYigUYQzIHEAAYigUYQzINEAAYigUYsQMYgwEYQzINEAAYigUYsQMYgwEYQ0ixCVAAWNUDcAB4AJABAJgBSKABqwKqAQE1uAEByAEA-AEBwgIREC4YgAQYsQMYgwEYxwEY0QPCAgsQABiABBixAxiDAcICCxAuGIAEGLEDGIMBwgIIEAAYgAQYsQPCAgsQLhiKBRixAxiDAcICExAuGIoFGLEDGIMBGMcBGNEDGEPiAwQYACBBiAYB&sclient=gws-wiz-serp" target="_blank" title=""><img title="" src={reactimage} alt="reactimage"/></a>
                </div>
                <div className="tech-box">
                    <a href="https://www.google.com/search?q=vscode&sca_esv=572573644&rlz=1C1CHBF_en-GBGB978GB978&ei=4CUnZeTxLISvhbIPkMKS2AU&ved=0ahUKEwjk4eHNie-BAxWEV0EAHRChBFsQ4dUDCBA&uact=5&oq=vscode&gs_lp=Egxnd3Mtd2l6LXNlcnAiBnZzY29kZTINEAAYigUYsQMYgwEYQzIHEAAYigUYQzIHEAAYigUYQzINEAAYigUYsQMYgwEYQzILEAAYgAQYsQMYgwEyCxAAGIAEGLEDGIMBMgsQABiABBixAxiDATIFEAAYgAQyBRAAGIAEMgUQABiABEjiB1AAWJsHcAB4AZABAJgBPKABwwKqAQE2uAEDyAEA-AEBwgIQEC4YigUYsQMYxwEY0QMYQ8ICERAuGIAEGLEDGIMBGMcBGNEDwgILEC4YigUYsQMYgwHCAggQABiABBixA-IDBBgAIEGIBgE&sclient=gws-wiz-serp" target="_blank" title="VisualStudioCode"><img title="" src={vsimage} alt="vsimage"/></a>
                </div>
                <div className="tech-box">
                    <a href="https://www.google.com/search?q=github&sca_esv=572573644&rlz=1C1CHBF_en-GBGB978GB978&ei=7yUnZc2eNLqShbIPqKqKqAE&ved=0ahUKEwiN0vzUie-BAxU6SUEAHSiVAhUQ4dUDCBA&uact=5&oq=github&gs_lp=Egxnd3Mtd2l6LXNlcnAiBmdpdGh1YjITEC4YgwEYxwEYsQMY0QMYigUYQzINEAAYigUYsQMYgwEYQzINEAAYigUYsQMYgwEYQzINEAAYigUYsQMYgwEYQzIHEAAYigUYQzINEAAYigUYsQMYgwEYQzINEAAYigUYsQMYgwEYQzINEAAYigUYsQMYgwEYQzINEAAYigUYsQMYgwEYQzIKEAAYigUYsQMYQzIiEC4YgwEYxwEYsQMY0QMYigUYQxiXBRjcBBjeBBjgBNgBAUiTBVAAWLQEcAB4AZABAJgBN6ABsQKqAQE2uAEDyAEA-AEBwgINEC4YigUYxwEY0QMYQ8ICExAuGIoFGLEDGIMBGMcBGNEDGEPCAgsQABiABBixAxiDAcICIhAuGIoFGLEDGIMBGMcBGNEDGEMYlwUY3AQY3gQY4ATYAQHiAwQYACBBiAYBugYGCAEQARgU&sclient=gws-wiz-serp" target="_blank" title="Github"><img title="" src={githubimage} alt="githubimage"/></a>
                </div>
                <div className="tech-box">
                    <a href="https://www.google.com/search?q=git&sca_esv=572573644&rlz=1C1CHBF_en-GBGB978GB978&ei=_SUnZe_bL-SVhbIP742FqAo&ved=0ahUKEwivzs7bie-BAxXkSkEAHe9GAaUQ4dUDCBA&uact=5&oq=git&gs_lp=Egxnd3Mtd2l6LXNlcnAiA2dpdDITEC4YigUYsQMYgwEYxwEY0QMYQzINEAAYigUYsQMYgwEYQzINEAAYigUYsQMYgwEYQzINEAAYigUYsQMYgwEYQzINEAAYigUYsQMYgwEYQzINEAAYigUYsQMYgwEYQzINEAAYigUYsQMYgwEYQzINEAAYigUYsQMYgwEYQzINEAAYigUYsQMYgwEYQzIHEAAYigUYQzIiEC4YigUYsQMYgwEYxwEY0QMYQxiXBRjcBBjeBBjgBNgBAUihA1AAWLkCcAB4AZABAJgBRKABvgGqAQEzuAEDyAEA-AEBwgINEC4YigUYxwEY0QMYQ8ICHBAuGIoFGMcBGNEDGEMYlwUY3AQY3gQY4ATYAQHCAgsQABiABBixAxiDAeIDBBgAIEGIBgG6BgYIARABGBQ&sclient=gws-wiz-serp" target="_blank" title="Git"><img title="" src={gitimage} alt="gitimage"/></a>
                </div>
                <div className="tech-box">
                    <a href="https://www.google.com/search?q=php&sca_esv=572573644&rlz=1C1CHBF_en-GBGB978GB978&ei=CyYnZbrfKOyBhbIP-_WlcA&ved=0ahUKEwj6kJ7iie-BAxXsQEEAHft6CQ4Q4dUDCBA&uact=5&oq=php&gs_lp=Egxnd3Mtd2l6LXNlcnAiA3BocDIQEAAYigUYsQMYgwEYyQMYQzIKEAAYigUYsQMYQzINEAAYigUYsQMYgwEYQzIIEAAYigUYkgMyBxAAGIoFGEMyBxAAGIoFGEMyBxAAGIoFGEMyBxAAGIoFGEMyBxAAGIoFGEMyBxAAGIoFGENIrAlQAFioBnAAeAGQAQCYAW2gAYcCqgEDMi4xuAEDyAEA-AEBwgILEAAYgAQYsQMYgwHCAgsQABiKBRixAxiDAcICCxAuGIoFGLEDGIMB4gMEGAAgQYgGAQ&sclient=gws-wiz-serp" target="_blank" title="PHP"><img title="" src={phpimage} alt="phpimage"/></a>
                </div>
                <div className="tech-box">
                    <a href="https://www.google.com/search?q=discord&rlz=1C1ONGR_enGB1046GB1046&oq=discord&gs_lcrp=EgZjaHJvbWUqEAgAEAAYgwEYsQMYyQMYgAQyEAgAEAAYgwEYsQMYyQMYgAQyDQgBEAAYgwEYsQMYgAQyDQgCEAAYgwEYsQMYgAQyDQgDEAAYgwEYsQMYgAQyDQgEEAAYgwEYsQMYgAQyDQgFEAAYgwEYsQMYigUyBggGEEUYPDIECAcQBdIBCDE4MjdqMGo0qAIAsAIA&sourceid=chrome&ie=UTF-8" target="_blank" title="Redux"><img title="" src={discordimage} alt="discordimage"/></a>
                </div>
        </div>
	</div>
</section>
</div>
</div>


</html>

)};