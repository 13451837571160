import React from 'react';
import './reviews.css';


export function Reviews() {
return (
<html>
    <section>
            <div class="reviews">
                <h3>Reviews</h3>
                <div class="review">
                    <p className="para9">"We were very happy with the professional service we recieved from them. The final website was perfect and has helped us get so many more members down on a Thursday evening!"</p>
                    <h3>Luke @ Cascade Street Dance <br></br><div className="stars">&#9733;&#9733;&#9733;&#9733;&#9733;</div></h3>
                </div>
                <div class="review">
                    <p className="para9">"I can't thank Charlie enough for his speed and professionalism in creating our website. Needless to say we are over the moon with the design and functionality and to get all that for such a great price is a real bonus."</p>
                    <h3>Tina @ Queens Estates <br></br><div className="stars">&#9733;&#9733;&#9733;&#9733;&#9733;</div></h3>
                </div>
                <div class="review">
                    <p className="para9">"Charlie worked very closely with me to create exactly what I wanted from my site. It's clear, informative and well designed. My clients keep up-to-date with whats going on with their classes easily!"</p>
                    <h3>Jason @ JPT <br></br><div className="stars">&#9733;&#9733;&#9733;&#9733;&#9733;</div></h3>
                </div>
            </div>
    </section>
</html>

)};