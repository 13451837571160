import React from 'react';
import './contactusbutton.css';
import { NavLink } from 'react-router-dom';


export function ContactUsButton() {
return (
<html>
    <div className="button1">
    <NavLink to="/contact"  onClick={() => {window.scroll(0, 0);}}><button data-aos="zoom-in" className="contactusbutton">Contact</button></NavLink>
    </div>
</html>

)};