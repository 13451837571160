import React from 'react';
import './main.css';
import { Navbar } from './Components/Navigationbar/navbar';
import { Footer } from './Components/Footer/footer';
import { BurgerMenu } from './Components/Burger Menu/burgermenu';

export function ContactApp() {

  const [checked, setChecked] = React.useState(false);

  const handleChange = () => {
    setChecked(!checked);
  };

  return (
<div className="ContactApp">
    <body>
    < Navbar />
    < BurgerMenu />
    <main>
       
<div data-aos="fade-up" className="maincontent2">
    <div className="content">
	      <h3>Every great idea starts with a conversation...</h3>
<br></br>
<form name="contact_form" action="./contact-form-handler.php" method="POST">
    <input type="text" placeholder="MY NAME IS" name="name" required minlength="3" maxlength="30" />
    <input type="text" placeholder="MY EMAIL IS" name="email" required minlength="6" maxlength="100" />
	  <input type="text" placeholder="MY MOBILE IS" name="mobile" required min="10" max="15" />
	  <textarea type="text" placeholder="I'D LIKE TO ENQUIRE ABOUT" name="message" rows="8" cols="48" required />
	  <label for="checkbox">
      Would you like to sign up to our Newsletter?
    </label>
	<br></br>
	<br></br>
  <label>
	<input 
  id="checkbox" 
  type="checkbox" 
  checked={checked}
  onChange={handleChange} />
  </label>
	<br></br>
	<br></br>
	<button className="sendbutton" role="button">Send</button>
</form>
</div>
<div className="content">
	<h3><strong>&#x2690;</strong><br></br><br></br><a href="https://www.linkedin.com/in/charlie-collins-27790119" target="_blank">Linkedin Profile</a></h3>
  <h3><strong>&#x260F;</strong><br></br><br></br><a href="tel:07825138432">07825138432</a></h3>
	<h3><strong>&#x2709;</strong><br></br><br></br><a href="mailto:charlie@madeyouclick.co.uk">charlie@madeyouclick.co.uk</a></h3>
	<h3><strong>&#9759;</strong><br></br><br></br><a className="fiverrlink" target="_blank" href="https://www.fiverr.com/madeyouclick/create-a-clear-bold-and-stylish-website-for-your-business">Fiverr</a></h3>
</div>
</div>
    
    </main>
    < Footer />
    </body>

</div>

)};

