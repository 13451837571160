import './App.css';
import "./Fonts/MarckScript-Regular.ttf";
import { RouterProvider, createBrowserRouter, createRoutesFromElements, Route} from 'react-router-dom';
import { HomepageApp } from './homepage';
import { PortfolioApp } from './portfolio';
import { AboutApp } from './about';
import { PricesApp } from './prices';
import { ContactApp } from './contact';
import { PrivacyApp } from './privacypolicy';
import { TermsAndCondsApp } from './termsandconds';
import { QueensEstatesApp } from './queensestates';
import { JPTApp } from './jpt';


const router = createBrowserRouter( createRoutesFromElements(

  <>
      <Route path="/" element={ <HomepageApp/>}>
      </Route>
      <Route path='homepage' element={ <HomepageApp/> } />
      <Route path='portfolio' element={ <PortfolioApp/> } />
      <Route path='about' element={ <AboutApp/> } />
      <Route path='prices' element={ <PricesApp/> } />
      <Route path='contact' element={ <ContactApp/> } />
      <Route path='privacypolicy' element={ <PrivacyApp/> } />
      <Route path='termsandconds' element={ <TermsAndCondsApp/> } />
      <Route path='queensestates' element={ <QueensEstatesApp/> } />
      <Route path='jpt' element={ <JPTApp/> } />
  </>
  
  ));

export function App() {
  return (

    <RouterProvider router={ router } />
  );
}

