import React from 'react';
import './homebutton.css';
import { NavLink } from 'react-router-dom';


export function HomeButton() {
return (
<html>
    <div className="button1">
    <NavLink to="/homepage"  onClick={() => {window.scroll(0, 0);}}><button data-aos="zoom-in" className="homebutton">Home</button></NavLink>
    </div>
</html>

)};