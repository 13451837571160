import React from 'react';
import './main.css';
import { Navbar } from './Components/Navigationbar/navbar';
import { HpHeader } from './Components/Homepageheader/hpheader';
import { ContactUsButton } from './Components/Contactusbutton/contactusbutton';
import { CounterBoxes } from './Components/Counterboxes/counterboxes';
import { StepsAndTech } from './Components/Stepsandtechnologies/stepsandtech';
import { Reviews } from './Components/Reviews/reviews';
import { Footer } from './Components/Footer/footer';
import { OurProjectsButton } from './Components/Ourprojectsbutton/ourprojectsbutton';
import { BurgerMenu } from './Components/Burger Menu/burgermenu';

export function HomepageApp() {
  return (
<div className="HomepageApp">
    <body>
    < Navbar />
    < BurgerMenu />
    <main>

    <section>
    < HpHeader />
            <p className="slide-right para1">
            We are a new and exciting Web Development Company in the United Kingdom. Our home is in <a href="https://www.google.com/maps/place/East+Sussex/@50.9404144,0.0363652,10z/data=!3m1!4b1!4m6!3m5!1s0x47dee0253a3551b9:0x94da6e8412e06b70!8m2!3d50.9085955!4d0.2494166!16zL20vMGh0OGg?entry=ttu" target="_blank" title="Open Google Maps">East Sussex</a>, United Kingdom.
            We are a "One-Stop-Shop" for all of your design, branding and development needs. Whether it's personal, business, blogs or e-commerce websites, we can help bring your business to life online.
            Our business mostly arrives through recommendations and from excellent online reviews which you can find on Google <a href="https://g.page/r/CRFSP-Q6mwYdEAI/review" target="_blank" title="Open Google Reviews">here</a>.
            These are testament to the quality of our work and the reputation we are building for delivering our clients projects to a high standard and on time.
            We work closely with our clients to help design and develop websites that match their specifications and exceed their expectations.
            </p>
    < OurProjectsButton />
    < CounterBoxes />
    < StepsAndTech />
    < Reviews />
    < ContactUsButton />
    </section>
    </main>
    < Footer />
    </body>
</div>
)};

