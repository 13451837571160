import React from 'react';
import './main.css';
import { Navbar } from './Components/Navigationbar/navbar';
import { Footer } from './Components/Footer/footer';
import { OurProjectsButton } from './Components/Ourprojectsbutton/ourprojectsbutton';
import { BurgerMenu } from './Components/Burger Menu/burgermenu';



export function QueensEstatesApp() {
  return (
<div className="QueensEstatesApp">

    <body>

    < Navbar />
	< BurgerMenu />

    <main>
    <p data-aos="zoom-in" className="para3 details">
	The website was created to give a clean and clear layout so 
	users can easily navigate it, whether they are looking for a 
	new house to buy or a valuation for their home in order to sell.
	The site was broken down into multiple components which come
	together to display the main website, allowing for the reloading
	of individual components instead of the entire page. The whole site
	is mobile responsive and has a completely different layout on phone 
	screens including a "burger menu" navigation bar and was built using 
	mainly React, JSX and PHP.
    <br></br>
	<br></br>
	There is no live link for this site as it was made for a start up
	business proposal.
	</p>
	<div className="projectcontent2">
		<div className="projectcontentimg">
			<div id="fsimg1" data-aos="slide-up" className="queensestatesimage1" onclick="document.getElementById('fsimg1').requestFullscreen()"></div>
		</div>
		<div data-aos="" class="projectcontent">
			<h3>Home Page</h3>
			 <p className="para11">
                The homepage consists of a large title and header with a simple and bold 
				navigation bar underneath, which has the opening hours and contact info 
				permanently displayed as key information. The mission statement follows 
				below as well as a link through to booking a valuation and a search bar 
				component to find a new home. 
				Beneath that is a six element news section that links through to news
				topics related to buying and selling homes in the UK, whether that's best
				places to live or the current average property prices within the country.
			</p>
		</div>
	</div>
	<div className="projectcontent2">
		<div data-aos="" class="projectcontent">
			<h3>Valuations Page</h3>
			<p className="para11">
                The valuations page consists of a form allowing people to request valuations
				and provide all the information the estate agent will need in order to book
				one in. The form uses PHP to send an email through to the agents main email
				address and lays out the users message in clear and easy to read format.
				The other half of the page breaks down what people can expect on a valuation.
				This manages their expectations and helpfully guides them through the valuation
				process step by step.
			</p>
		</div>
		<div className="projectcontentimg">
			<div id="fsimg2" data-aos="slide-up" className="queensestatesimage2" onclick="document.getElementById('fsimg2').requestFullscreen()"></div>
		</div>
	</div>
	<div className="projectcontent2">
		<div className="projectcontentimg">
			<div id="fsimg3" data-aos="slide-up" className="queensestatesimage3" onclick="document.getElementById('fsimg3').requestFullscreen()"></div>
		</div>
		<div data-aos="" className="projectcontent">
			<h3>Properties Page</h3>
			<p className="para11">
                The property page clearly displays all the current properties that 
				Queens Estates has on the market or that have recently sold.
				They are laid out in a grid of three columns wide and runs for as many
				properties that are currently listed. It displays key 
				information such as the main front photo, price, number of bedrooms
				and a short tag line to grab the user's attention.
				The property pictures are animated on hover and link through to that
				specific property, on click, where the user can find more photos and 
				information on that home.
			</p>
		</div>
	</div>
	<div className="projectcontent2">
		<div data-aos="" className="projectcontent">
			<h3>About Us Page</h3>
			<p className="para11">
                The About Us page shows all the members of staff that currently work
				for Queens Estates. It displays their photo, name, titles and a little bit
				of information about the person, so users can get to know the team who
				will be selling their home a bit better, adding in that personal connection.
				Below that is also each individual team members contact email address.
				You can also see what the websites footer looks like in this screenshot.
				It displays icons that link to the agency's Social Media accounts on Facebook,
				Instagram and TikTok where there are more photos and video walkthroughs of
				the properties that are for sale.
			</p>
		</div>
		<div className="projectcontentimg">
			<div id="fsimg4" data-aos="slide-up" className="queensestatesimage4" onclick="document.getElementById('fsimg4').requestFullscreen()"></div>
		</div>
	</div>
	<div className="projectcontent2">
		<div className="projectcontentimg">
			<div id="fsimg5" data-aos="slide-up" className="queensestatesimage5" onclick="document.getElementById('fsimg5').requestFullscreen()"></div>
		</div>
		<div data-aos="" class="projectcontent">
			<h3>Property Listing Part 1</h3>
			<p className="para11">
                When the user clicks on the property they wish to view on the properties
				page, they are then taken to that specific propeties listing page.
				On this page is the same key information as before as well as a prompt to
				book in a viewing with contact details at the top again. Below this is a 
				fully interactive and animated side scrolling image grid. As the user moves
				thier mouse across the pictures the current image expands and all the others
				collapse and an image filter is applied.
				Beneath the image grid is then a detailed description of the property where
				the agent has plenty of space to write an excellent overview of the home.
			</p>
		</div>
	</div>
	<div className="projectcontent2">
		<div data-aos="" className="projectcontent">
			<h3>Property Listing Part 2</h3>
			<p className="para11">
                Once the user finishes reading the detailed description and scrolls further
				down the page, they will find a brief description of the properties location 
				in the local area. This includes any nearby amenities and transportation links.
				Below this is then a list of bullet points highlighting and summerising the key 
				features of the property to remind the user a final time about the property and
				the homes Energy Performance Certificate is displayed on the right handside.
				Right at the bottom of the page is a button to take the user back to the previous
				properties page so they can continue browsing.
			</p>
		</div>
		<div className="projectcontentimg">
			<div id="fsimg6" data-aos="slide-up" className="queensestatesimage6" onclick="document.getElementById('fsimg6').requestFullscreen()"></div>
		</div>
	</div>

    < OurProjectsButton />

    </main>
    < Footer />
    </body>

</div>

)};

