import React from 'react';
import './navbar.css'
import { NavLink } from 'react-router-dom';

export function Navbar() {
return (
<html>
    <nav>
        <NavLink to="/homepage"  onClick={() => {window.scroll(0, 0);}}><h6>MYC</h6></NavLink>
        <ul className="nav-bar-whole">
            <li><NavLink className="navbaritem" to="/homepage" activeClassName="active"  onClick={() => {window.scroll(0, 0);}}>Home<span></span></NavLink></li>
            <li><NavLink className="navbaritem" to="/portfolio" activeClassName="active"  onClick={() => {window.scroll(0, 0);}}>Portfolio<span></span></NavLink></li>
            <li><NavLink className="navbaritem" to="/about" activeClassName="active"  onClick={() => {window.scroll(0, 0);}}>TEAM<span></span></NavLink></li>
            <li><NavLink className="navbaritem" to="/prices" activeClassName="active"  onClick={() => {window.scroll(0, 0);}}>Prices<span></span></NavLink></li>
            <li><NavLink className="navbaritem1 contacthighlight" to="/contact" activeClassName="active"  onClick={() => {window.scroll(0, 0);}}>Contact<span></span></NavLink></li>
	    </ul>
    </nav>
</html>

)};