import React from 'react';
import './hpheader.css'
import headerimage from '../../Images/hpheaderbg.jpg';
import { useRef } from 'react';

export function HpHeader() {

    const ref = useRef(null);
    const handleClick = () => {
ref.current?.scrollIntoView({behavior: 'smooth'});
};

return (

<html>
    <header>
        <div className="headerwrapperdiv">
            <div className="headertext">
                <h1>
                    <span className="offset-header offset-header-odd"><span>Welcome to</span></span>
                </h1>
                <h2>
                    <span class="offset-header offset-header-even"><span>Made You Click</span></span>
                </h2>
                <p>
                    <span className="offset-header offset-header-odd"><span>The perfect way to get your 
                    <br>
                    </br>
                    business and website noticed -
                    <br>
                    </br>
                    in a world that is more
                    <br>
                    </br>
                    online than ever
                    </span></span>
                </p>
            </div>
                <div className="textbackground slide-right">
                </div>
                <img className="headerimage" title="Header Image" src={headerimage} alt="headerimage"/>
                <div className="textbackground2 slide-left">
                </div>
                <div className="textbackground3 slide-right"><p><span className="offset-header offset-header-odd"><span>We are now<br></br>proud to be</span></span><span class="offset-header offset-header-even"><span>on <a className='fiverrlink' href="https://www.fiverr.com/madeyouclick/create-a-clear-bold-and-stylish-website-for-your-business" target="_blank" title="Open Fiverr">Fiverr</a>!</span></span></p>
                </div>
        </div>
            <div id="arrowButton" class="animatearrow glowarrow down-arrow" onClick={handleClick}></div>
        <div>
</div>
    </header>
        <div className="reference" ref={ref}></div>
</html>

)};

