import React from 'react';
import './main.css';
import { Navbar } from './Components/Navigationbar/navbar';
import { Footer } from './Components/Footer/footer';
import { OurProjectsButton } from './Components/Ourprojectsbutton/ourprojectsbutton';
import { BurgerMenu } from './Components/Burger Menu/burgermenu';



export function JPTApp() {
  return (
<div className="JPTApp">

    <body>

    < Navbar />
	< BurgerMenu />

    <main>
    	<p data-aos="zoom-in" className="para3 details">

			I really enjoyed making this project as the client asked for a
			bold, clear and stylish website that would grab people's attention
			when they visited. Advertising their personal training business,
			where it was located, what their mission was, how they can help their 
			clients and to give them a way to contact the business quickly and easily.
			I wanted the site to be smooth and easily useable whilst providing all
			the necessary information. The whole site is mobile responsive and has a
			completely different layout on phone screens including a "burger menu" 
			navigation bar and was built using mainly React, JSX and PHP.
			<br></br>
			<br></br>
			JPT Transformations is currently hosted and maintained by us.
			<br></br>
			<br></br>
			You can find the live site here: <a href="https://www.jasonpt.co.uk" target="_blank" title="JPT">www.jasonpt.co.uk</a>

		</p>
	<div className="projectcontent2">
		<div className="projectcontentimg">
			<div id="fsimg1" data-aos="slide-up" className="jptimage1" onclick="document.getElementById('fsimg1').requestFullscreen()"></div>
		</div>
		<div data-aos="" class="projectcontent">
			<h3>Home Page</h3>
			 <p className="para11">
                
				The homepage consists of a bold landing title and the business' mission
				statement. Across the top is the business logo with obvious social media 
				buttons and a clear animated navigation bar to the rest of the site. There
				is an "Enquire Now" button at the bottom to make it quick and easy to get 
				in touch with them. Then at the bottom there is the website footer displaying
				social media links again, terms and conditions, privacy policy and the business
				contact details of email and phone number.

			</p>
		</div>
	</div>
	<div className="projectcontent2">
		<div data-aos="" class="projectcontent">
			<h3>Transformation Page</h3>
			<p className="para11">
                
				The transformation page has an animated flex box of images that on "mouse over" 
				or "on press" allow the user to bring up an overlay of text about of some of JPT's 
				clients, so potential new clients can see how effective the personal training is. Again 
				there is an "Enquire Now" button at the top of the page that allows for easy navigation so the user
				can take action and get in touch.

			</p>
		</div>
		<div className="projectcontentimg">
			<div id="fsimg2" data-aos="slide-up" className="jptimage2" onclick="document.getElementById('fsimg2').requestFullscreen()"></div>
		</div>
	</div>
	<div className="projectcontent2">
		<div className="projectcontentimg">
			<div id="fsimg3" data-aos="slide-up" className="jptimage3" onclick="document.getElementById('fsimg3').requestFullscreen()"></div>
		</div>
		<div data-aos="" className="projectcontent">
			<h3>About Page</h3>
			<p className="para11">

                The about us page shows an image of Jason the owner of JPT and a small
				bio detailing his experience and how he wants to help people get into shape.
				This allows clients to get to know thier trainer better and provide them with
				some background. People buy people so this is very important. Then there is
				information regarding a new app that JPT have recently had developed to help
				people achieve their goals. Finally there is a small section on how the payment
				procedure and cancellation works. With another call to action "Enquire Now" button.

			</p>
		</div>
	</div>
	<div className="projectcontent2">
		<div data-aos="" className="projectcontent">
			<h3>Contact Page</h3>
			<p className="para11">
                
				The contact page has the business' contact details at the top. Then underneath
				is a contact form that allows the user to enter their details in so JPT can easily
				get back in touch with them and it gives JPT all the information they would need
				from a new enquiry including; Name, Email, Mobile, Age and Goals. That form then
				sends the information to JPT's business email in a nicely laid out template. On
				the right hand side is information regarding where JPT do their sessions, with
				the full address displayed and a Google Maps link to help users find them on their
				PC or on their mobile whilst walking to the Gym.

			</p>
		</div>
		<div className="projectcontentimg">
			<div id="fsimg4" data-aos="slide-up" className="jptimage4" onclick="document.getElementById('fsimg4').requestFullscreen()"></div>
		</div>
	</div>

    < OurProjectsButton />

    </main>
    < Footer />
    </body>

</div>

)};

