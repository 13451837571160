import React from 'react';
import './main.css';
import { Navbar } from './Components/Navigationbar/navbar';
import { HomeButton } from './Components/Homebutton/homebutton';
import { Footer } from './Components/Footer/footer';
import { BurgerMenu } from './Components/Burger Menu/burgermenu';

export function PrivacyApp() {
  return (
<div className="PrivacyApp">
    <body>
    < Navbar />
    < BurgerMenu />
    <main>

<div data-aos="fade-up" className="privacypolicy">
<p className="para8">
    <strong><center>Privacy Policy</center></strong>
<br></br>
<br></br>
    This Privacy Policy applies between you, the User of this Website, and <strong>Made You Click LTD</strong>, the owner and provider of this Website. <strong>Made You Click LTD</strong> takes the privacy of your information very seriously. This Privacy Policy applies to our use of any and all Data collected by us or provided by you in relation to your use of the Website. 
<br></br>
<br></br>
    This Privacy Policy should be read alongside, and in addition to, our Terms and Conditions, which can be found at:
<br></br>
    https://madeyouclick.co.uk/termsandconds
<br></br>
<br></br>
<strong>Please read this Privacy Policy carefully.</strong>
<br></br>
<br></br>
<strong>Definitions and Interpretation</strong>
<br></br>
<br></br>
    1.	In this Privacy Policy, the following definitions are used:
<br></br>
<br></br>
<br></br>
    <center><strong>Data</strong>
<br></br>
    Collectively all information that you submit to <strong>Made You Click LTD</strong> via the Website. This definition incorporates, where applicable, the definitions provided in the Data Protection Laws;
<br></br>
<br></br>
    <strong>Data Protection Laws</strong>
<br></br>
    Any applicable law relating to the processing of Personal Data, including but not limited to the GDPR, and any national implementing and supplementary laws, regulations and secondary legislation;
<br></br>
<br></br>
    <strong>GDPR</strong>
<br></br>
    The UK General Data Protection Regulation;
<br></br>
<br></br>
    <strong>Made You Click LTD, we or us</strong>
<br></br>
    <strong>Made You Click LTD</strong>, a company incorporated in England and Wales with registered number 14705887 whose registered office is at:
<br></br> 
    <strong>Apartment 8, Atherstone Heights, Tunbridge Wells, Kent, TN40FH;</strong>
<br></br>
<br></br>
    <strong>User or you</strong>
<br></br>
    Any third party that accesses the Website and is not either (i) employed by <strong>Made You Click LTD</strong> and acting in course of their employment or (ii) engaged as a consultant or otherwise providing services to <strong>Made You Click LTD</strong> and accessing the Website in connection with the provision of such services; and
            Website	The website that you are currently using, www.madeyouclick.co.uk, and any sub-domains of this site unless expressly excluded by their own terms and conditions.
    </center>
<br></br>
<br></br>
    2.	In this Privacy Policy, unless the context requires a different interpretation:
<br></br>
    a.	The singular includes the plural and vice versa;
<br></br>
    b.	References to sub-clauses, clauses, schedules or appendices are to sub-clauses, clauses, schedules or appendices of this Privacy Policy;
<br></br>
    c.	A reference to a person includes firms, companies, government entities, trusts and partnerships;
<br></br>
    d.	"including" is understood to mean "including without limitation";
<br></br>
    e.	Reference to any statutory provision includes any modification or amendment of it;
<br></br>
    f.	The headings and sub-headings do not form part of this Privacy Policy.
<br></br>
<br></br>
<strong>Scope of this Privacy Policy</strong>
<br></br>
    3.	This Privacy Policy applies only to the actions of <strong>Made You Click LTD</strong> and Users with respect to this Website. It does not extend to any websites that can be accessed from this Website including, but not limited to, any links we may provide to social media websites. 
<br></br>
    4.	For purposes of the applicable Data Protection Laws, <strong>Made You Click LTD</strong> is the "data controller". This means that <strong>Made You Click LTD</strong> determines the purposes for which, and the manner in which, your Data is processed.
<br></br>
<br></br>
    <strong>Data Collected</strong>
<br></br>
    5.	We may collect the following Data, which includes personal Data, from you:
<br></br>
    a.	Name;
<br></br>
    b.	Contact Information such as email addresses and telephone numbers;
<br></br>
    c.	IP address (automatically collected);
<br></br>
    In each case, in accordance with this Privacy Policy.
<br></br>
<br></br>
    <strong>How We Collect Data</strong>
<br></br>
    6.	We collect Data in the following ways:
<br></br>
    a.	Data is given to us by you; and
<br></br>
    b.	Data is collected automatically
<br></br>
<br></br>
    <strong>Data That Is Given to Us by You</strong>
<br></br>
    7.	<strong>Made You Click LTD</strong> will collect Data in a number of ways, for example:
<br></br>
    a.	When you contact us through the Website, by telephone, post, email or through any other means;
<br></br>
    b.	When you complete surveys that we use for research purposes (although you are not obligated to respond to them);
<br></br>
    c.	when you elect to receive marketing communications from us;
<br></br>
    d.	when you use our services;
<br></br>
    e.	when you use our contact us form;
<br></br>
    in each case, in accordance with this Privacy Policy.
<br></br>
<br></br> 
    <strong>Data That is Collected Automatically</strong>
<br></br>
    8.	To the extent that you access the Website, we will collect your Data automatically, for example:
<br></br>
    a.	We automatically collect information regarding your IP address. This is to prevent online "bots" from spamming our email, telephone or contact us form, by blocking their IP addresses from accessing our site.
<br></br>
<br></br>
    <strong>Our Use of Data</strong>
<br></br>
    9.	Any or all of the above Data may be required by us from time to time in order to provide you with the best possible service and experience when using our Website. Specifically, Data may be used by us for the following reasons:
<br></br>
    a.	Internal record keeping;
<br></br>
    b.	Improvement of our products / services;
<br></br>
    c.	Transmission by email of marketing materials that may be of interest to you;
<br></br>
    d.	Contact for market research purposes which may be done using email, telephone, fax, or mail. Such information may be used to customise or update the Website;
    In each case, in accordance with this Privacy Policy.
<br></br>
    10.	We may use your Data for the above purposes if we deem it necessary to do so for our legitimate interests. If you are not satisfied with this, you have the right to object in certain circumstances (see the section headed "Your rights" below).
<br></br>
    11.	For the delivery of direct marketing to you via-email, we'll need your consent, whether via an opt-in or soft-opt-in:
<br></br>
    a.	A soft-opt-in consent is a specific type of consent which applies when you have previously engaged with us (for example, you contact us to ask us for more details about a particular product/service, and we are marketing similar products/services). Under "soft-opt-in" consent, we will take your consent as given unless you opt-out.
<br></br>   
    b.	For other types of e-marketing, we are required to obtain your explicit consent; that is, you need to take positive and affirmative action when consenting by, for example, checking a tick box that we'll provide.
<br></br>
    c.	If you are not satisfied with our approach to marketing, you have the right to withdraw consent at any time. To find out how to withdraw your consent, see the section headed "Your rights" below.
<br></br>
<br></br>
    <strong>Who We Share Data With</strong>
<br></br>
    12.	We may share your Data with the following groups of people for the following reasons;
<br></br>
    a.	Our employees, agents and/or professional advisors - so we can contact you in relation to your enquiry;
<br></br>
    In each case, in accordance with this Privacy Policy.
<br></br>
<br></br>
    <strong>Keeping Data Secure</strong>
<br></br>
    13.	We will use technical and organisational measures to safeguard your Data, for example:
<br></br>
    a.	Access to your account is controlled by a password and a user name that is unique to you.
<br></br>
    b.	We store your Data on secure servers.
<br></br>
    14.	Technical and organisational measures include measures to deal with any suspected data breach. If you suspect any misuse or loss or unauthorised access to your Data, please let us know immediately by contacting us via this email address: <strong>charlie@madeyouclick.co.uk</strong>
<br></br>
    15.	If you want detailed information from Get Safe Online on how to protect your information and your computers and devices against fraud, identity theft, viruses and many other online problems, please visit www.getsafeonline.org. Get Safe Online is supported by HM Government and leading businesses.
<br></br>
<br></br>
    <strong>Data Retention</strong>
<br></br>
    16.	Unless a longer retention period is required or permitted by law, we will only hold your Data on our systems for the period necessary to fulfil the purposes outlined in this Privacy Policy or until you request that the Data be deleted.
<br></br>
    17.	Even if we delete your Data, it may persist on backup or archival media for legal, tax or regulatory purposes.
<br></br>
<br></br>
    <strong>Your Rights</strong>
<br></br>
    18.	You have the following rights in relation to your Data:
<br></br>
    a.	Right to access - the right to request (i) copies of the information we hold about you at any time, or (ii) that we modify, update or delete such information. If we provide you with access to the information we hold about you, we will not charge you for this, unless your request is "manifestly unfounded or excessive." Where we are legally permitted to do so, we may refuse your request. If we refuse your request, we will tell you the reasons why. 
<br></br>
    b.	Right to correct - the right to have your Data rectified if it is inaccurate or incomplete. 
<br></br>
    c.	Right to erase - the right to request that we delete or remove your Data from our systems.
<br></br>
    d.	Right to restrict our use of your Data - the right to "block" us from using your Data or limit the way in which we can use it.
<br></br>
    e.	Right to data portability - the right to request that we move, copy or transfer your Data.
<br></br>
    f.	Right to object - the right to object to our use of your Data including where we use it for our legitimate interests.
<br></br>
    19.	To make enquiries, exercise any of your rights set out above, or withdraw your consent to the processing of your Data (where consent is our legal basis for processing your Data), please contact us via this email address: <strong>charlie@madeyouclick.co.uk</strong>
<br></br>
    20.	If you are not satisfied with the way a complaint you make in relation to your Data is handled by us, you may be able to refer your complaint to the relevant data protection authority. For the UK, this is the Information Commissioner's Office (ICO). The ICO's contact details can be found on their website at https://ico.org.uk/.
<br></br>
    21.	It is important that the Data we hold about you is accurate and current. Please keep us informed if your Data changes during the period of which we hold it.
<br></br>
<br></br>
    <strong>Links to Other Websites</strong>
<br></br>
    22.	This website may, from time to time, provide links to other websites. We have no control over such websites and are not  responsible for the content of these websites. This Privacy Policy does not extend to your use of such websites. You are advised to read the Privacy Policy or statement of other websites prior to using them.
<br></br>
<br></br>
    <strong>Changes of Business Ownership and Control</strong>
<br></br>
    23.	<strong>Made You Click LTD</strong> may, from time to time, expand or reduce our business and this may involve the sale and/or the transfer of control of all or part of <strong>Made You Click LTD</strong>. Data provided by Users will, where it is relevant to any part of our business so transferred, be transferred along with that part and the new owner or newly controlling party will, under the terms of this Privacy Policy, be permitted to use the Data for the purposes for which it was originally supplied to us.
<br></br>
    24.	We may also disclose Data to a prospective purchaser of our business or any part of it.
<br></br>
    25.	In the above instances, we will take steps with the aim of ensuring your privacy is protected.
<br></br>
<br></br>
    <strong>General</strong>
<br></br>
    26.	You may not transfer any of your rights under this Privacy Policy to any other person. We may transfer our rights under this Privacy Policy where we reasonably believe your rights will not be affected.
<br></br>
    27.	If any court or competent authority finds that any provision of this Privacy Policy (or part of any provision) is invalid, illegal or unenforceable, that provision or part-provision will, to the extent required, be deemed to be deleted, and the validity and enforceability of the other provisions of this Privacy Policy will not be affected.
<br></br>
    28.	Unless otherwise agreed, no delay, act or omission by a party in exercising any right or remedy will be deemed a waiver of that, or any other, right or remedy.
<br></br>
    29.	This Agreement will be governed by and interpreted in accordance with the law of England and Wales. All disputes arising under the Agreement will be subject to the exclusive jurisdiction of the English and Welsh courts.
<br></br>
<br></br>
    <strong>Changes to This Privacy Policy</strong>
<br></br>
    30.	<strong>Made You Click LTD</strong> reserves the right to change this Privacy Policy as we may deem necessary from time to time or as may be required by law. Any changes will be immediately posted on the Website and you are deemed to have accepted the terms of the Privacy Policy on your first use of the Website following the alterations.
<br></br>
<br></br>
    You may contact <strong>Made You Click LTD</strong> by email at charlie@madeyouclick.co.uk.
<br></br>
<br></br>
    This Privacy Policy was created on <strong>03 October 2023.</strong>
</p>
</div>

    < HomeButton />
    </main>
    < Footer />
    </body>

</div>

)};

