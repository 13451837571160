import React, {useState} from "react";
import './burgermenu.css';
import { NavLink } from 'react-router-dom';
import fiverrimg from '../../Images/fiverr.png';
import instaimg from '../../Images/insta.png';
import tiktokimg from '../../Images/tiktok.png';

export function BurgerMenu() {

const [burger_class, setBurgerClass] = useState("burger-bar unclicked")
const [menu_class, setMenuClass] = useState("menu hidden")
const [isMenuClicked, setIsMenuClicked] = useState(false)

const updateMenu = () => {
    if(!isMenuClicked) {
        setBurgerClass("burger-bar clicked")
        setMenuClass("menu visible")
    }
    else {
        setBurgerClass("burger-bar unclicked")
        setMenuClass("menu hidden")
    }
    setIsMenuClicked(!isMenuClicked)
}

    return (
        <div className="burger-menu-whole">
            
                <div className="burger-menu" onClick={updateMenu}>
                    <div className={burger_class} ></div>
                    <div className={burger_class} ></div>
                    <div className={burger_class} ></div>
                </div>
            

                <div className={menu_class}>
                    <nav>
                        <ul>
                            <li><NavLink className="navbaritem" to="/homepage" activeClassName="active"  onClick={() => {window.scroll(0, 0);}}>Home<span></span></NavLink></li>
                            <li><NavLink className="navbaritem" to="/portfolio" activeClassName="active"  onClick={() => {window.scroll(0, 0);}}>Portfolio<span></span></NavLink></li>
                            <li><NavLink className="navbaritem" to="/about" activeClassName="active"  onClick={() => {window.scroll(0, 0);}}>TEAM<span></span></NavLink></li>
                            <li><NavLink className="navbaritem" to="/prices" activeClassName="active"  onClick={() => {window.scroll(0, 0);}}>Prices<span></span></NavLink></li>
                            <li><NavLink className="navbaritem1 contacthighlight" to="/contact" activeClassName="active"  onClick={() => {window.scroll(0, 0);}}>Contact<span></span></NavLink></li>
                        </ul>
                    </nav>
                    <div className="stayconnected">
                        <h4>Join Us on:</h4>
                            <a href="https://www.instagram.com/made.you.click/" target="_blank" title="Instagram"><img title="" src={instaimg} alt="Instagram"/></a>
                            <a href="https://www.facebook.com/madeyouclick.co.uk" target="_blank" title="Facebook"><img title="" src={tiktokimg} alt="Facebook"/></a>
                            <a href="https://www.fiverr.com/madeyouclick/create-a-clear-bold-and-stylish-website-for-your-business" target="_blank" title="Fiverr"><img title="" src={fiverrimg} alt="Fiverr"/></a>
                    </div>
                </div>
        </div>




)};

