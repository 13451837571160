import React from 'react';
import './main.css';
import { Navbar } from './Components/Navigationbar/navbar';
import { ContactUsButton } from './Components/Contactusbutton/contactusbutton';
import { Footer } from './Components/Footer/footer';
import { NavLink } from 'react-router-dom';
import cccimage from '../src/Images/cccimage.png'
import csdimage from '../src/Images/csdimage.png'
import qeimage from '../src/Images/qeimage.jpg';
import jptimage from '../src/Images/JPT/jptimage1.png';
import { BurgerMenu } from './Components/Burger Menu/burgermenu';


export function PortfolioApp() {
  return (
<div className="PortfolioApp">
    <body>
    < Navbar />
    < BurgerMenu />
    <main>

    <section>
    <div className='portfoliobox'>
    <NavLink to="/queensestates"  onClick={() => {window.scroll(0, 0);}}>
      <img data-aos="slide-up" data-aos-duration="1000" className="portfolioimg" title="Queens Estates" src={qeimage} alt=""/>
      <h3 data-aos="slide-down">
      Queens Estates
      <br></br>
      <em>Online Estate Agents across Kent, UK</em>
      </h3>
    </NavLink>
    <NavLink to="/jpt"  onClick={() => {window.scroll(0, 0);}}>
      <img data-aos="slide-up" data-aos-duration="2000" className="portfolioimg" title="JPT Body Transformation" src={jptimage} alt=""/>
      <h3 data-aos="slide-down">
      JPT Body Transformation
      <br></br>
      <em>Personal Trainer in Tunbridge Wells, Kent</em>
      </h3>
    </NavLink>
    </div>
    < ContactUsButton />
    </section>
    </main>
    < Footer />
    </body>

</div>

)};

