import React, { useEffect } from 'react';
import './counterboxes.css';
import rocketimage from '../../Images/rocket.png';
import clipboardimage from '../../Images/clipboard.png';
import bubblesimage from '../../Images/bubbles.png';
import Aos from "aos";
import "aos/dist/aos.css"

export function CounterBoxes() {
	useEffect(() => {
        Aos.init({duration: 2000});
    },[])

return (
<html>
<div id="counter-container">
	<div class="counterboxes">
		<div data-aos="flip-right" className="counterbox">
			<img src={rocketimage} alt="Date we were Founded"/>
			<h3>2023</h3>
			<h5>Company<br></br>Founded</h5>
	    </div>
		<div data-aos="flip-right" className="counterbox">
			<img src={clipboardimage} alt="Amount of Projects Completed"/>
			<h3>5</h3>
			<h5>Projects<br></br>Completed</h5>
		</div>
		<div data-aos="flip-right" className="counterbox">
			<img src={bubblesimage} alt="Amount of Google Reviews"/>
			<h3>2</h3>
			<h5>Google<br></br>Reviews</h5>
		</div>
	</div>
</div>
</html>

)};
